import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LeftMenu from '../LeftMenu/LeftMenu';
import {Route} from 'react-router-dom';
import Declarants from '../Declarants/Declarants';
import Manufacturers from '../Manufacturers/Manufacturers';
import Products from '../Products/Products';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff'
    }
}));

const Content = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LeftMenu/>
            <div className={classes.content}>
                <Route path="/declarants" render={() => <Declarants/>}/>
                <Route path="/manufacturers" render={() => <Manufacturers/>}/>
                <Route path="/products" render={() => <Products/>}/>
            </div>
        </div>
    );
};
export default Content;
