import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LoginForm from './LoginForm';
import {login} from '../../redux/authentication/authenticationReducer';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    paperRoot: {
        width: '18rem',
        padding: '1rem'
    }
}));

const Login = props => {
    const classes = useStyles();
    const {login} = props;

    const handleSubmit = (data) => {
        login(data);
    };

    return (
        <Grid
            container
            justify='center'
            alignItems='center'
            className={classes.root}
        >
            <Paper
                classes={{
                    root: classes.paperRoot
                }}
            >
                <LoginForm onSubmit={handleSubmit}/>
            </Paper>
        </Grid>
    );
};
export default connect(null, {login})(Login);
