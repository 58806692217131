import {reducer as formReducer} from 'redux-form'
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import authenticationReducer from './authentication/authenticationReducer';
import postReducer from './post/postReducer';

const reducers = combineReducers({
    authentication: authenticationReducer,
    post: postReducer,
    form: formReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;
