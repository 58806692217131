import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import TableContainer from '@material-ui/core/TableContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DeclarantsForm from './DeclarantsForm';
import {connect} from 'react-redux';
import {getToken} from '../../redux/authentication/authenticationSelector';
import {createPost, getPost, updatePost} from '../../redux/post/postReducer';
import {getLoad, getStatePost} from '../../redux/post/postSelector';
import Preloader from '../Preloader/Preloader';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: '0.5rem 0.5rem 0.5rem 1rem',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    searchField: {
        marginLeft: 'auto',
        width: '20rem'
    },
    notSelect: {
        userSelect: 'none'
    }
}));

const columns = [
    {text: 'Наименование организации', field: 'sTitle'},
    {text: 'УНП', field: 'register'},
    {text: 'Телефон', field: 'phone'},
    {text: 'Почта', field: 'email'},
    {text: 'ФИО', field: 'fio'},
];

const categories = {
    id: 2,
    slug: 'declarants'
};

const Declarants = props => {
    const classes = useStyles();
    const {
        token,
        declarants,
        declarantsLoad,
        createPost,
        getPost,
        updatePost
    } = props;
    const [selectRowIndex, setSelectRowIndex] = useState(null);
    const [btnName, setBtnName] = useState('Добавить');

    useEffect(() => {
        getPost(categories);
    }, [getPost]);

    const [searchText, setSearchText] = useState('');
    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectRowIndex(null);
        setBtnName('Добавить');
    };

    const handleDoubleClick = (event) => {
        setSelectRowIndex(event.currentTarget.getAttribute('data-key'));
        setBtnName('Обновить');
        handleClickOpen();
    };

    const dataRows = [];
    if (declarants) {
        for (let i = 0; i < declarants.length; i++) {
            for (let key in declarants[i].data) {
                if (declarants[i].data[key].toString().toUpperCase().indexOf(searchText.toUpperCase()) !== -1) {
                    dataRows.push(declarants[i]);
                    break;
                }
            }
        }
    }

    const handleSubmit = (data) => {
        switch (btnName) {
            case 'Добавить':
                createPost(data, categories, token);
                break;
            case 'Обновить':
                updatePost(data, categories, declarants[selectRowIndex].id, token);
                break;
            default:
                break;
        }
        handleClose();
    };
    return (
        <>
            <div className={classes.root}>
                <Typography variant='body1'>Заявители</Typography>
                <TextField
                    className={classes.searchField}
                    id='search-field'
                    label='Поиск'
                    value={searchText}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton onClick={handleClickOpen}>
                    <AddBoxIcon/>
                </IconButton>
            </div>
            <Divider/>
            {declarantsLoad && <Preloader/>}
            {!declarantsLoad && <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, columnIndex) => (
                                <TableCell
                                    align={columnIndex === 0 ? 'inherit' : 'right'}
                                    key={columnIndex}
                                >{column.text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataRows.map((row, rowIndex) => (
                            <TableRow
                                key={row.id}
                                data-key={rowIndex}
                                className={classes.notSelect}
                                hover
                                onDoubleClick={handleDoubleClick}
                            >
                                {columns.map((column, columnIndex) => (
                                    <TableCell
                                        align={columnIndex === 0 ? 'inherit' : 'right'}
                                        key={columnIndex}
                                    >{row.data[column.field]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Заявитель'}</DialogTitle>
                <DialogContent id='alert-dialog-description'>
                    <DeclarantsForm
                        close={handleClose}
                        onSubmit={handleSubmit}
                        initialValues={declarants && selectRowIndex ? declarants[selectRowIndex].data : {}}
                        btnName={btnName}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    token: getToken(state),
    declarants: getStatePost(state, categories.slug),
    declarantsLoad: getLoad(state, categories.slug)
});
export default connect(mapStateToProps, {
    createPost,
    getPost,
    updatePost
})(Declarants);
