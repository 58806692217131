import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {getAuthentication} from '../../redux/authentication/authenticationSelector';
import Content from '../Content/Content';
import Login from '../Login/Login';
import {checkIsAuth} from '../../redux/authentication/authenticationReducer';
import Preloader from '../Preloader/Preloader';

const Root = props => {
    const {authentication, checkIsAuth} = props;

    useEffect(() => {
        checkIsAuth(authentication);
    }, [checkIsAuth]);

    if (authentication.isLoad) {
        if (authentication.isAuth) {
            return <Content/>
        } else {
            return <Login/>
        }
    } else {
        return <Preloader/>
    }
};
const mapStateToProps = (state) => ({
    authentication: getAuthentication(state)
});
export default connect(mapStateToProps, {checkIsAuth})(Root);
