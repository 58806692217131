export const getAuthentication = (state) => {
    return state.authentication
};

export const getUserName = (state) => {
    return state.authentication.user_display_name
};

export const getToken = (state) => {
    return state.authentication.token
};
