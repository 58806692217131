import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import TableContainer from '@material-ui/core/TableContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {connect} from 'react-redux';
import {getToken} from '../../redux/authentication/authenticationSelector';
import {createPost, getPost, updatePost} from '../../redux/post/postReducer';
import {getLoad, getStatePost} from '../../redux/post/postSelector';
import Preloader from '../Preloader/Preloader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProductsForm from './ProductsForm';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: '0.5rem 0.5rem 0.5rem 1rem',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    leftField: {
        marginLeft: 'auto',
        width: '20rem'
    },
    topField: {
        width: '20rem',
        marginLeft: '1rem'
    },
    notSelect: {
        userSelect: 'none'
    }
}));

const columns = [
    {text: 'Наименование и обозначение продукции', field: 'title'},
    {text: 'Масса', field: 'weight'},
    {text: 'Вид упаковки', field: 'packaging'},
    {text: 'Код ТН ВЭД ЕАЭС', field: 'tnved'},
];

const categories = {
    id: 4,
    slug: 'products'
};

const categoriesManufacturers = {
    id: 3,
    slug: 'manufacturers'
};

const categoriesDeclarants = {
    id: 2,
    slug: 'declarants'
};

const getOption = (data, id) => {
    const result = [];
    if (data) {
        data.forEach(item => {
            if (item.data.declarantID === id) {
                result.push(item);
            }
        });
    } else {
        return [{data: {title: ''}}];
    }
    return result;
};

const Products = props => {
    const classes = useStyles();
    const {
        token,
        declarants,
        declarantsLoad,
        manufacturers,
        manufacturersLoad,
        products,
        productsLoad,
        createPost,
        getPost,
        updatePost
    } = props;
    const [selectRowIndex, setSelectRowIndex] = useState(null);
    const [btnName, setBtnName] = useState('Добавить');

    useEffect(() => {
        getPost(categories);
        getPost(categoriesDeclarants);
        getPost(categoriesManufacturers);
    }, [getPost]);

    const [searchText, setSearchText] = useState('');
    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectRowIndex(null);
        setBtnName('Добавить');
    };

    const handleDoubleClick = (event) => {
        if (selectedDeclarantID !== null && selectedManufacturerID !== null) {
            setSelectRowIndex(event.currentTarget.getAttribute('data-key'));
            setBtnName('Обновить');
            handleClickOpen();
        }
    };

    const [selectedManufacturerID, setSelectedManufacturerID] = useState(null);
    const [manufacturerValue, setManufacturerValue] = useState(null);
    const handleManufacturerChange = (event, value) => {
        if (value !== null) {
            setSelectedManufacturerID(value.id);
            setManufacturerValue(value);
        } else {
            setSelectedManufacturerID(null);
            setManufacturerValue(null);
        }
    };

    const [selectedDeclarantID, setSelectedDeclarantID] = useState(null);
    const handleDeclarantChange = (event, value) => {
        setManufacturerValue(null);
        if (value !== null) {
            setSelectedDeclarantID(value.id);
        } else {
            setSelectedDeclarantID(null);
        }
    };

    const dataRows = [];
    if (products) {
        for (let i = 0; i < products.length; i++) {
            if (selectedDeclarantID === null) {
                for (let key in products[i].data) {
                    if (products[i].data[key].toString().toUpperCase().indexOf(searchText.toUpperCase()) !== -1) {
                        dataRows.push(products[i]);
                        break;
                    }
                }
            } else if (products[i].data.declarantID === selectedDeclarantID && selectedManufacturerID === null) {
                for (let key in products[i].data) {
                    if (products[i].data[key].toString().toUpperCase().indexOf(searchText.toUpperCase()) !== -1) {
                        dataRows.push(products[i]);
                        break;
                    }
                }
            } else if (products[i].data.declarantID === selectedDeclarantID && products[i].data.manufacturerID === selectedManufacturerID) {
                for (let key in products[i].data) {
                    if (products[i].data[key].toString().toUpperCase().indexOf(searchText.toUpperCase()) !== -1) {
                        dataRows.push(products[i]);
                        break;
                    }
                }
            }
        }
    }

    const handleSubmit = (data) => {
        data = {
            ...data,
            declarantID: selectedDeclarantID,
            manufacturerID: selectedManufacturerID
        };
        switch (btnName) {
            case 'Добавить':
                createPost(data, categories, token);
                break;
            case 'Обновить':
                updatePost(data, categories, products[selectRowIndex].id, token);
                break;
            default:
                break;
        }
        handleClose();
    };
    return (
        <>
            <div className={classes.root}>
                <Typography variant='body1'>Продукция</Typography>
                <Autocomplete
                    id="declarant"
                    className={classes.leftField}
                    options={declarants ? declarants : [{data: {sTitle: ''}}]}
                    getOptionLabel={(option) => option.data.sTitle}
                    onChange={handleDeclarantChange}
                    renderInput={(params) => <TextField {...params} label="Заявитель"/>}
                />
                <Autocomplete
                    id="manufacturer"
                    className={classes.topField}
                    options={getOption(manufacturers, selectedDeclarantID)}
                    getOptionLabel={(option) => option.data.title}
                    onChange={handleManufacturerChange}
                    value={manufacturerValue}
                    disabled={selectedDeclarantID === null}
                    renderInput={(params) => <TextField {...params} label="Изготовитель"/>}
                />
                <TextField
                    className={classes.topField}
                    id='search-field'
                    label='Поиск'
                    value={searchText}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton
                    onClick={handleClickOpen}
                    disabled={selectedDeclarantID === null || selectedManufacturerID === null}
                >
                    <AddBoxIcon/>
                </IconButton>
            </div>
            <Divider/>
            {(manufacturersLoad && declarantsLoad && productsLoad) && <Preloader/>}
            {(!manufacturersLoad && !declarantsLoad && !productsLoad) && <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, columnIndex) => (
                                <TableCell
                                    align={columnIndex === 0 ? 'inherit' : 'right'}
                                    key={columnIndex}
                                >{column.text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataRows.map((row, rowIndex) => (
                            <TableRow
                                key={row.id}
                                data-key={rowIndex}
                                className={classes.notSelect}
                                hover
                                onDoubleClick={handleDoubleClick}
                            >
                                {columns.map((column, columnIndex) => (
                                    <TableCell
                                        align={columnIndex === 0 ? 'inherit' : 'right'}
                                        key={columnIndex}
                                    >{row.data[column.field]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Продукция'}</DialogTitle>
                <DialogContent id='alert-dialog-description'>
                    <ProductsForm
                        close={handleClose}
                        onSubmit={handleSubmit}
                        initialValues={products && selectRowIndex ? products[selectRowIndex].data : {}}
                        btnName={btnName}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    token: getToken(state),
    declarants: getStatePost(state, categoriesDeclarants.slug),
    declarantsLoad: getLoad(state, categoriesDeclarants.slug),
    manufacturers: getStatePost(state, categoriesManufacturers.slug),
    manufacturersLoad: getLoad(state, categoriesManufacturers.slug),
    products: getStatePost(state, categories.slug),
    productsLoad: getLoad(state, categories.slug),
});
export default connect(mapStateToProps, {
    createPost,
    getPost,
    updatePost
})(Products);
