import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Field, reduxForm} from 'redux-form';
import Typography from '@material-ui/core/Typography';
import renderTextField from '../FormComponent/renderTextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    actionSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '1rem 0 0'
    },
    marginBottom: {
        marginBottom: '1rem'
    }
}));

const validate = values => {
    const errors = {};
    const requiredFields = [
        'title',
        'sTitle',
        'register',
        'urAddress',
        'phone',
        'email',
        'inFace',
        'fio',
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Обязательно для заполнения'
        }
    });
    return errors
};

const DeclarantsForm = props => {
    const classes = useStyles();
    const {valid, error, handleSubmit, close, btnName} = props;
    return (
        <form onSubmit={handleSubmit}>
            {error && <Typography color={'error'} className={classes.marginBottom}>{error}</Typography>}
            <Field
                name='title'
                component={renderTextField}
                label='Полное наименование организации'
                margin='dense'
                multiline
            />
            <Field
                name='sTitle'
                component={renderTextField}
                label='Сокращенное наименование организации'
                margin='dense'
                multiline
            />
            <Field
                name='register'
                component={renderTextField}
                label='УНП'
                margin='dense'
                multiline
            />
            <Field
                name='urAddress'
                component={renderTextField}
                label='Место нахождения (адрес юридического лица)'
                margin='dense'
                multiline
            />
            <Field
                name='deAddress'
                component={renderTextField}
                label='Адрес (адреса) места осуществления деятельности'
                margin='dense'
                multiline
            />
            <Field
                name='phone'
                component={renderTextField}
                label='Номер телефона'
                margin='dense'
            />
            <Field
                name='email'
                component={renderTextField}
                label='Адрес электронной почты'
                margin='dense'
            />
            <Field
                name='inFace'
                component={renderTextField}
                label='В лице (должность, ФИО (полностью), основание)'
                margin='dense'
                multiline
            />
            <Field
                name='fio'
                component={renderTextField}
                label='ФИО (Именительный падеж)'
                margin='dense'
            />
            <div className={classes.actionSection}>
                <Button
                    color='primary'
                    onClick={close}
                >Отмена</Button>
                <Button
                    color='primary'
                    type='submit'
                    disabled={!valid}
                >{btnName}</Button>
            </div>
        </form>
    )
};
export default reduxForm({
    form: 'declarantsForm',
    validate
})(DeclarantsForm);
