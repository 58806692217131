import * as axios from 'axios';

const instance = axios.create({
    baseURL: 'https://grifon.cert-tc.by/wordpress/wp-json'
});

export const authenticationAPI = {
    login(data) {
        return instance.post('/jwt-auth/v1/token', data);
    },
    checkIsAuth(token) {
        return instance.post('/jwt-auth/v1/token/validate', null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    }
};

export const postAPI = {
    addPost(data, categories, token) {
        return instance.post('/wp/v2/posts', {
            title: data.title,
            categories,
            status: 'publish',
            content: JSON.stringify(data),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    },
    updatePost(data, id, token) {
        return instance.post(`/wp/v2/posts/${id}`, {
            content: JSON.stringify(data),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    },
    getPost(categories) {
        return instance.get(`/worespa/posts/${categories}`);
    }
};


