import {postAPI} from '../../api/api';

const GET = 'postReducer/GET';
const LOAD = 'postReducer/LOAD';

const initialState = {
    declarants: {
        load: false
    },
    manufacturers: {
        load: false
    },
    products: {
        load: false
    },
};

const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET:
            return {
                ...state,
                ...action.state
            };
        case LOAD:
            return {
                ...state,
                ...action.state
            };
        default:
            return state
    }
};
export default postReducer;

export const createPost = (data, categories, token) => {
    return async (dispatch) => {
        let response = await postAPI.addPost(data, [categories.id], token);
        if (200 <= response.status <= 300) {
            dispatch(getPost(categories));
        }
    };
};

export const updatePost = (data, categories, id, token) => {
    return async (dispatch) => {
        let response = await postAPI.updatePost(data, id, token);
        if (200 <= response.status <= 300) {
            dispatch(getPost(categories));
        }
    };
};

const _load = (state) => ({type: LOAD, state});
const load = (data) => {
    return (dispatch) => {
        dispatch(_load(data));
    }
};

const _get = (state) => ({type: GET, state});
export const getPost = (categories) => {
    return async (dispatch) => {
        dispatch(load({
            [categories.slug]: {
                load: true
            }
        }));
        let response = await postAPI.getPost(categories.id);
        if (200 <= response.status <= 300) {
            dispatch(_get({
                [categories.slug]: {
                    data: response.data,
                    load: false
                }
            }));
        }
    };
};
