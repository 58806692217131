import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {getUserName} from '../../redux/authentication/authenticationSelector';
import {logout} from '../../redux/authentication/authenticationReducer';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: '0.5rem 0 0.5rem 1rem',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

const UserInfo = props => {
    const classes = useStyles();
    const {userName, logout} = props;
    return (
        <div className={classes.root}>
            <Typography variant="body1">{userName}</Typography>
            <IconButton aria-label="delete" onClick={logout}>
                <ExitToAppIcon />
            </IconButton>
        </div>
    );
};
const mapStateToProps = (state) => ({
    userName: getUserName(state)
});
export default connect(mapStateToProps, {logout})(UserInfo);
