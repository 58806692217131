import {authenticationAPI} from '../../api/api';
import {stopSubmit} from 'redux-form';

const LOGIN = 'authenticationReducer/LOGIN';
const LOGOUT = 'authenticationReducer/LOGOUT';
const LOADING = 'authenticationReducer/LOADING';

const initialState = {
    isAuth: false,
    isLoad: false
};

const localStorage = window.localStorage;

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                ...action.state
            };
        case LOGOUT:
            return {
                ...state,
                ...action.state
            };
        case LOADING:
            return {
                ...state,
                ...action.state
            };
        default:
            return state;
    }
};
export default authenticationReducer;

const _login = (state) => ({type: LOGIN, state});
export const login = (data) => {
    return async (dispatch) => {
        let response = await authenticationAPI.login(data);
        if (response.data.code === '[0]') {
            dispatch(stopSubmit('loginForm', {_error: response.data.message}));
        } else {
            localStorage.setItem('token', response.data.data.userData.token);
            localStorage.setItem('user_display_name', response.data.data.userData.user_display_name);
            dispatch(_login({
                ...response.data.data.userData,
                isAuth: true,
                isLoad: true
            }));
        }
    };
};

const _logout = (state) => ({type: LOGOUT, state});
export const logout = () => {
    return async (dispatch) => {
        localStorage.clear();
        dispatch(_logout({
            token: null,
            user_display_name: null,
            isAuth: false
        }));
    }
};

const _loading = (state) => ({type: LOADING, state})
export const checkIsAuth = (state) => {
    return async (dispatch) => {
        let token = '';
        if (state.isAuth) {
            token = state.token;
        } else if (localStorage.getItem('token')) {
            token = localStorage.getItem('token');
        } else {
            dispatch(_loading({isLoad: true}));
            return null
        }
        let response = await authenticationAPI.checkIsAuth(token);
        if (response.data.code === '[1]' && !state.isAuth) {
            dispatch(_login({
                token: token,
                user_display_name: localStorage.getItem('user_display_name'),
                isAuth: true,
                isLoad: true
            }));
        }
        if (response.data.code === '[0]') {
            dispatch(logout())
        }
    }
};
