import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import UserInfo from '../UserInfo/UserInfo';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer/Drawer';
import Divider from '@material-ui/core/Divider';
import LeftMenuItem from './LeftMenuItem';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '15rem',
        height: '100%'
    },
    paper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
}));

const menu = [
    {url: 'declarations', text:'Декларации'},
    {url: 'declarants', text:'Заявители'},
    {url: 'manufacturers', text:'Изготовители'},
    {url: 'products', text:'Продукция'},
    {url: 'protokoly', text:'Протоколы'},
];

const LeftMenu = () => {
    const classes = useStyles();
    return (
        <Drawer
            className={classes.rootContainer}
            variant="permanent"
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
            anchor="left"
        >
            <UserInfo/>
            <Divider/>
            <List>
                {
                    menu.map((data, index) => (
                        <LeftMenuItem data={data} key={index} />
                    ))
                }
            </List>
        </Drawer>
    );
};
export default LeftMenu;
