import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import {CssBaseline} from '@material-ui/core';
import Root from './component/Root/Root';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100vw',
        height: '100vh'
    }
}));

const theme = createMuiTheme({});

const App = () => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Provider store={store}>
                    <div className={classes.root}>
                        <CssBaseline/>
                        <Root/>
                    </div>
                </Provider>
            </BrowserRouter>
        </ThemeProvider>
    );
};


export default App;
