import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {NavLink} from 'react-router-dom';

const LeftMenuItem = props => {
    const {data} = props;
    return (
        <ListItem
            component={NavLink}
            to={data.url}
            button
            activeClassName={'Mui-selected'}
        >
            <ListItemText primary={data.text}/>
        </ListItem>
    );
};
export default LeftMenuItem;
